export default {
    SET_RESPONSE (state, response) {
        state.response = response
    },
    SET_USER (state, user) {
        state.user = user
    },
    SET_TOKEN (state, token) {
        state.token = token
    },
    SET_REFRESH_TOKEN (state, refresh_token) {
        state.refresh_token = refresh_token
    },
    SET_IS_DASHBOARD (state, isDashboard) {
        state.isDashboard = isDashboard
    },
    SET_CONFIRMATION (state, confirmation) {
        state.confirmation = confirmation
    },
    SET_ATTACHMENT_PREVIEW (state, attachment_preview) {
        state.attachmentPreview = attachment_preview
    },
    SET_OVERLAY (state, overlay) {
        state.overlay = overlay
    },
    SET_NOTIFICATION (state, notification) {
        state.notification = notification
    },
    SET_FIRST_LOGIN (state, first_login) {
        state.first_login = first_login
    },
    SET_PERIOD (state, period) {
        state.period = period
    }

}
