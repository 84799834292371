<template>
  <v-row>
    <v-col class="mx-auto" cols="12" lg="8" xl="7">
      <v-card class="pa-4" flat>
        <v-card-title class="d-block px-0">
          <back-button-component/>
          <div class="d-flex">
            <span class="font-weight-bold d-block">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}</span>
          </div>
        </v-card-title>
        <v-card rounded-lg>
          <v-card-text>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :search="search"
                :server-items-length="total"
                item-key="id"
                show-select>
              <template v-slot:top>
                <v-toolbar class="px-0" flat>
                  <v-row class="ma-0 pa-0">
                    <v-col class="px-0" cols="4">
                      <v-text-field v-model="search"
                                    placeholder="Find"
                                    prepend-inner-icon="mb-search"
                                    type="text"></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end px-0" cols="8">
                      <v-btn :to="{name: routes.create}"
                             color="primary" height="49" rounded width="30">
                        <v-icon color="#fff">mb-plus</v-icon>
                      </v-btn>
                      <template v-if="selected.length > 0">

                        <v-menu
                            bottom
                            left
                            offset-x
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                height="49" icon
                                v-bind="attrs"
                                width="49"
                                v-on="on"
                            >
                              <v-icon color="primary">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                                v-for="(item, i) in menu"
                                :key="i"
                                :disabled="!item.type"
                                @click="item.method()"
                            >
                              <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.data-table-select="{ isSelected, item, select }">
                <template v-if="item.status !== 'done'">
                  <v-checkbox :value="isSelected" @change="select($event)"></v-checkbox>
                </template>
              </template>
              <template v-slot:item.status="{ item }">
                <template v-if="item.status">
                  <v-icon color="success">mdi-check-circle</v-icon>
                </template>
                <template v-else>
                  <v-checkbox v-model="item.status"
                              @change="markAsDone(item.id)"></v-checkbox>
                </template>
              </template>
              <template v-slot:item.title="{ item }">
                {{ item.title | truncate(20) }}
              </template>
              <template v-slot:item.attachments="{ item }">
                <div v-if="item.attachments.length > 0" class="d-flex justify-start">
                  <v-icon color="success">mb-paper-clip</v-icon>
                  <span class="ml-2">{{ item.attachments.length }} {{ $tc('file | files', item.attachments.length) }} attached </span>
                </div>
                <div v-else class="d-flex justify-start">
                  <v-icon color="error">mdi-close</v-icon>
                  <span class="ml-2">No attaches</span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn :ripple="false" :to="{ name: routes.show, params: { id: item.id } }" color="transparent" icon small>
                  <v-icon color="primary" small>mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn :ripple="false" :to="{ name: routes.edit, params: { id: item.id } }" color="transparent" icon small>
                  <v-icon color="primary" small>mb-pencil</v-icon>
                </v-btn>
                <v-btn :ripple="false" icon small @click="openShareDialog(item.id)">
                  <v-icon color="primary" small>mb-paper-plane</v-icon>
                </v-btn>
                <v-btn :ripple="false" icon small @click="openConfirmation(item.id)">
                  <v-icon color="primary" small>mb-trash-can</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
    <v-col class="pa-0" cols="12" lg="4" xl="5">
      <v-row class="pa-0">
        <v-col class="mt-16 mx-auto" cols="10">
          <sbi-score-component :title="type.replace(/^\w/, (c) => c.toUpperCase())" :url="endpoint"/>
        </v-col>
        <v-col cols="12">

        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="dialog"
              :max-width="$vuetify.breakpoint.width * 0.25"
              persistent>
      <v-card>
        <v-card-title>Do you really want to delete this {{ type }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="error" text @click="deleteItem(item.id)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="massDialog"
              :max-width="$vuetify.breakpoint.width * 0.28"
              persistent>
      <v-card>
        <v-card-title>Do you really want to {{ title }} selected {{ type }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="massDialog = false">Cancel</v-btn>
          <v-btn color="error" text @click="confirmChosenAction(title)">{{ title }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shareDialog"
              :max-width="$vuetify.breakpoint.width * 0.3"
              persistent>
      <validation-observer ref="share" v-slot="{ passes }">
        <v-form @submit.prevent="passes( shareItem )">
          <v-card>
            <v-card-title>Please enter the e-mail address of the person you want to share with this {{ type }} </v-card-title>
            <v-card-text>
              <validation-provider v-slot="{ errors }" name="e-mail" rules="required|email">
                <v-text-field v-model="form.email"
                              :disabled="sharing"
                              :error-messages="errors"
                              autocomplete="email"
                              hint="Enter person's e-mail"
                              persistent-placeholder
                              placeholder="Please, enter person's e-mail"
                              type="email">
                  <template v-slot:label>
                    <label class="font-weight-bold">Person's e-mail</label>
                  </template>
                </v-text-field>
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="cancel">Cancel</v-btn>
              <v-btn :loading="sharing" color="success" text type="submit">Share</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "ItemsListComponent",
  props: {
    endpoint: String,
    type: String,
    routes: Object
  },
  data () {
    return {
      menu: [
        {
          method: () => this.openMassConfirmation('delete'),
          title: 'Delete selected',
          type: true
        },
        {
          method: () => this.openMassConfirmation('close'),
          title: 'Mark as done selected',
          type: this.type !== 'top' && this.type !== 'unwow'
        }
      ],
      selected: [],
      item: null,
      dialog: false,
      massDialog: false,
      shareDialog: false,
      items: [],
      loading: true,
      page: 1,
      options: {},
      total: null,
      search: null,
      period: null,
      sbi: null,
      form: {},
      sharing: false,
      title: null
    }
  },
  watch: {
    params: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  beforeMount() {
    this.getItems()
    this.getItemsSBIByPeriod(1)
  },
  methods: {
    openConfirmation (id) {
      this.item = id
      this.dialog = true
    },
    deleteItem () {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${this.item}`)
          .then(() => {
            this.dialog = false
            this.getItems()
          })
    },
    getItemsFromAPI () {

    },
    getItems () {
      this.loading = true
      this.$http.get(`${process.env.VUE_APP_API_URL}${this.url}`)
          .then(response => {

            const {sortBy, sortDesc, page, itemsPerPage} = this.options
            this.items = response.data

            this.total = this.items.length

            let search = this.search ? this.search.trim().toLowerCase() : null;

            if (search) {
              this.items = this.items.filter(item => {
                return Object.values(item)
                    .join(",")
                    .toLowerCase()
                    .includes(search);
              });
            }

            if (sortBy.length === 1 && sortDesc.length === 1) {
              this.items = this.items.sort((a, b) => {
                const sortA = a[sortBy[0]]
                const sortB = b[sortBy[0]]

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1
                  if (sortA > sortB) return -1
                  return 0
                } else {
                  if (sortA < sortB) return -1
                  if (sortA > sortB) return 1
                  return 0
                }
              })
            }

            if (itemsPerPage > 0) {
              this.items = this.items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            }
            this.loading = false
          })
    },
    getItemsSBIByPeriod (period) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/sbi/${this.endpoint}/${period}`)
          .then(response => {
            this.sbi = response.data
          })
    },
    cancel () {
      this.shareDialog = false
      this.$refs.share.reset()
      this.$worker.resetForm(this.form)
    },
    openShareDialog (id) {
      this.form.id = id
      this.form.type = this.type.slice(0, -1)
      this.shareDialog = true
    },
    shareItem () {
      this.sharing = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/share`, this.form)
          .then(() => {
            this.shareDialog = false
            this.$refs.share.reset()
            this.$worker.resetForm(this.form)
            this.sharing = false
          })
          .catch(() => {
            this.sharing = false
          })
    },
    openMassConfirmation (title) {
      this.title = title
      this.massDialog = true
    },
    confirmChosenAction (action) {
      switch (action) {
        case 'delete':
          this.massDeleting()
          break
        case 'close':
          this.massMarkAsDone()
          break
        default:
          break
      }
    },
    massDeleting () {
      this.$http.post(`${process.env.VUE_APP_API_URL}/group-deleting/${this.endpoint}`, {
          ids: this.selectedItems
      })
          .then(() => {
            this.getItems()
            this.massDialog = false
          })
          .then(() => {
            this.getItemsSBIByPeriod(this.period ? this.period : 1)
          })
          .finally(() => {
            this.selected = []
          })
    },
    markAsDone (id) {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/status/${this.endpoint}/${id}`)
          .then(() => {
            this.getItems()
          })
    },
    massMarkAsDone () {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/status/close`, {
        ids: this.selectedItems,
        type: this.type
      })
          .then(() => {
            this.getItems()
            this.massDialog = false
          })
          .then(() => {
            this.getItemsSBIByPeriod(this.period ? this.period : 1)
          })
          .finally(() => {
            this.selected = []
          })
    }
  },
  computed: {
    headers () {
      return this.$headers[this.endpoint]
    },
    url () {
      return `/${this.endpoint}?page=${this.options.page}`
    },
    params() {
      return {
        ...this.options,
        query: this.search
      };
    },
    periods () {
      return this.$periods.values
    },
    selectedItems () {
      const items = []
      this.selected.map( item => {
        items.push(item.id)
      })
      return items
    }
  }
}
</script>

<style scoped>

</style>