<template>
  <v-card flat class="pa-4">
    <v-card-title class="d-block px-0 mt-11">
      <div class="d-flex">
        <span class="font-weight-bold d-block">Available reports</span>
      </div>
    </v-card-title>
    <v-card rounded-lg>
      <v-card-text>
        <v-data-table
            :search="search"
            :headers="headers"
            :items="reports"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading">
          <template v-slot:top>
            <v-toolbar flat>
              <v-row class="ma-0 pa-0">
                <v-col cols="4" class="px-0">
                  <v-text-field type="text"
                                prepend-inner-icon="mb-search"
                                placeholder="Find"
                                v-model="search"></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            <template v-if="item.status || item.status === 0">
              <span :style="`color: ${item.status.color}`">{{ item.status.value }}</span>
            </template>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="transparent" :to="{ name: 'dashboard.report.show', params: { id: item.id } }" icon :ripple="false">
              <v-icon small color="primary">mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn small color="transparent" :to="{ name: 'dashboard.report.edit', params: { id: item.id } }" icon :ripple="false">
              <v-icon small color="primary">mb-pencil</v-icon>
            </v-btn>
            <v-btn small icon :ripple="false" @click="openConfirmation(item.id)">
              <v-icon small color="primary">mb-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog"
              persistent
              :max-width="$vuetify.breakpoint.width * 0.25">
      <v-card>
        <v-card-title>Do you really want to delete this report?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">Cancel</v-btn>
          <v-btn text color="error" @click="deleteItem(report.id)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "ReportDataTableComponent",
  data () {
    return {
      dialog: false,
      report: null,
      reports: [],
      loading: true,
      page: 1,
      options: {},
      total: null,
      search: null,
    }
  },
  watch: {
    params: {
      handler() {
        this.getReports()
      },
      deep: true,
    },
  },
  beforeMount() {
    this.getReports()
  },
  mounted() {
    this.$root.$on('getReports', () => {
      this.getReports()
    })
  },
  methods: {
    openConfirmation (id) {
      this.report = id
      this.dialog = true
    },
    deleteItem () {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/reports/${this.report}`)
          .then(() => {
            this.dialog = false
            this.getReports()
          })
    },
    getReports () {
      this.loading = true
      this.$http.get(`${process.env.VUE_APP_API_URL}${this.url}`)
          .then(response => {
            const {sortBy, sortDesc, page, itemsPerPage} = this.options
            this.reports = response.data
            this.total = this.reports.length

            let search = this.search ? this.search.trim().toLowerCase() : null;

            if (search) {
              this.reports = this.reports.filter(item => {
                return Object.values(item)
                    .join(",")
                    .toLowerCase()
                    .includes(search);
              });
            }

            if (sortBy.length === 1 && sortDesc.length === 1) {
              this.reports = this.reports.sort((a, b) => {
                const sortA = a[sortBy[0]]
                const sortB = b[sortBy[0]]

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1
                  if (sortA > sortB) return -1
                  return 0
                } else {
                  if (sortA < sortB) return -1
                  if (sortA > sortB) return 1
                  return 0
                }
              })
            }

            if (itemsPerPage > 0) {
              this.reports = this.reports.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            }
            this.loading = false
          })
    },
  },
  computed: {
    headers () {
      return this.$headers.reports
    },
    url () {
      return `/reports?page=${this.options.page}`
    },
    params() {
      return {
        ...this.options,
        query: this.search
      };
    }
  }
}
</script>

<style scoped>

</style>