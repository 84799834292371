<template>
<v-dialog v-model="preview.show"
          persistent>
  <v-card color="transparent"
          class="mx-auto"
          flat
          width="45%"
          style="position: relative">
    <v-card-text>
      <v-img class="mx-auto" max-width="45%" contain :src="preview.image">
        <v-btn fab color="primary"
               @click="closePreview"
               dark
               style="position: absolute; top: 5px; right: 5px;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-img>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "PreviewAttachmentComponent",
  methods: {
    closePreview() {
      this.$store.dispatch('setAttachmentPreview', { show: false, image: undefined })
    }
  },
  computed: {
    preview () {
      return this.$store.getters.GET_ATTACHMENT_PREVIEW
    }
  }
}
</script>

<style scoped>

</style>