<template>
  <v-row>
    <v-col cols="7" class="mt-6 mx-auto">
      <validation-observer ref="itemCreate" v-slot="{ passes }">
        <v-form @submit.prevent="passes( submit )">
          <v-card flat>
            <v-card-title class="d-block">
              <back-button-component/>
              <span class="font-weight-bold d-block">Write down and save your {{ type }}</span>
            </v-card-title>
            <v-card-text>
              <v-card class="rounded-lg" :loading="loading">
                <v-card-text class="pa-8">
                  <validation-provider name="headline" rules="required" v-slot="{ errors }">
                    <v-text-field type="text"
                                  autocomplete="title"
                                  :error-messages="errors"
                                  v-model="form.title"
                                  :hint="`Enter your ${type}'s headline`"
                                  persistent-placeholder
                                  :placeholder="`Your ${type}'s headline`">
                      <template v-slot:label>
                        <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s headline</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="description" rules="required" v-slot="{ errors }">
                    <v-textarea type="text"
                                autocomplete="description"
                                :error-messages="errors"
                                v-model="form.description"
                                :hint="`Enter your ${type}'s description`"
                                persistent-placeholder
                                :placeholder="`Your ${type}'s description`">
                      <template v-slot:label>
                        <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s description</label>
                      </template>
                    </v-textarea>
                  </validation-provider>
                  <v-switch inset v-model="form.pinned">
                    <template v-slot:label>
                      <label class="font-weight-bold">Would you like to pin your {{ type }} on top?</label>
                    </template>
                  </v-switch>
                  <v-row>
                    <v-col cols="12" class="ma-0 mt-3">
                      <label class="font-weight-bold">Add attachments to your {{ type }}</label>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                      <v-row class="ma-0 pa-0 d-flex">
                        <v-col v-for="(image, i) in form.attachments"
                               style="max-width: 75px; position: relative"
                               :key="i">
                          <v-btn
                              @click="deleteAttachment(i)"
                              x-small
                              style="position: absolute; top: -1px; right: 2px; z-index: 2"
                              dark
                              fab
                              max-width="20"
                              max-height="20"
                              class="px-0"
                              color="error">
                            <v-icon x-small>mdi-close</v-icon>
                          </v-btn>
                          <v-img class="elevation-1" :src="image"></v-img>
                        </v-col>
                        <v-col class="d-flex align-center">
                          <v-btn @click="openFileInput" icon color="primary" x-large>
                            <v-icon color="primary">mb-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" style="position: absolute; left: -100%; width: 1px">
                      <validation-provider name="Attachments" rules="max_files:5" v-slot="{ errors }">
                        <v-file-input ref="file" v-model="form.file"
                                      @change="chooseFiles"
                                      accept="image/jpeg,png"
                                      multiple
                                      :error-messages="errors">
                        </v-file-input>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="pb-8 px-8">
                  <v-spacer/>
                  <v-btn width="89" height="49" rounded
                         class="d-flex align-center font-weight-bold"
                         color="secondary" type="button">
                    <span class="text-capitalize">Cancel</span>
                  </v-btn>
                  <v-btn :loading="loading"
                         width="127" height="49" rounded class="d-flex align-center font-weight-bold"
                         color="primary" type="submit">
                    <span class="text-capitalize">Save {{ type }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
    <v-col cols="5" class="pa-0">
      <v-row class="pa-0">
        <v-col cols="10" class="mt-16 mx-auto">
          <v-card tile flat class="rounded-lg pa-0" >
            <v-card-title class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}s SBI Score</v-card-title>
            <v-card class="rounded-lg">
              <v-card-subtitle class="font-weight-bold">
                <v-row class="ma-0 pa-0">
                  <v-col cols="12">
                    <v-tabs id="periodTabs" background-color="#F6F6F6"
                            class="rounded-xl"
                            v-model="period" grow centered hide-slider
                            :style="`height: ${$vuetify.breakpoint.height * 0.05}px`">
                      <template v-for="period in periods">
                        <v-tab class="rounded-xl" :key="period.text"
                               @change="getItemsSBIByPeriod(period.value)"
                               :active-class="period.text === period.text ? 'elevation-2 white' : ''"
                               :ripple="false">{{ period.text }}</v-tab>
                      </template>
                    </v-tabs>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text style="height: 80%" class="d-flex justify-center align-center">
              <span class="font-weight-black primary--text display-4">
                {{ sbi ? sbi : 1 }}
              </span>
              </v-card-text>
              <v-card-actions class="px-8" style="height: 20%; background-color: rgba(0, 0, 0, .018) ">
                <v-icon color="lightgray">mdi-information-outline</v-icon>
                <v-btn :ripple="false" plain text color="lightgray">
                  <span class="text-lowercase">more about</span>&nbsp;SBI
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="12">

        </v-col>
      </v-row>
    </v-col>
  </v-row>

</template>
<script>

export default {
  name: "CreateItemComponent",
  props: {
    endpoint: String,
    type: String,
  },
  data () {
    return {
      period: null,
      sbi: null,
      loading: false,
      form: {
        attachments: [],
        file: []
      }
    }
  },
  beforeMount() {
    this.getItemsSBIByPeriod(1)
  },
  methods: {
    getItemsSBIByPeriod (period) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/sbi/${this.endpoint}/${period}`)
          .then(response => {
            this.sbi = response.data
          })
    },
    chooseFiles(files) {
      files.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.form.attachments.push(reader.result)
        }
        if (file) {
          reader.readAsDataURL(file)
        }
      })
    },
    deleteAttachment(index) {
      this.form.attachments.splice(index, 1)
      this.form.file.splice(index, 1)
    },
    submit () {
      this.loading = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}`, this.form)
          .then(() => {
            this.$refs.itemCreate.reset()
            this.form = this.$worker.resetForm(this.form)
            this.loading = false
          })

    },
    openFileInput() {
      this.$refs.file.$refs.input.click();
    }
  },
  computed: {
    periods () {
      return this.$periods.values
    }
  }
}
</script>

<style scoped>

</style>