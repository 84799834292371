import { render, staticRenderFns } from "./TodayTab.vue?vue&type=template&id=b9c5e446&scoped=true&"
import script from "./TodayTab.vue?vue&type=script&lang=js&"
export * from "./TodayTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9c5e446",
  null
  
)

export default component.exports