<template>
  <v-row class="d-flex justify-center ma-0 pa-0 fill-height">
    <v-col cols="9" md="12" lg="12" xl="12" class="py-0 px-0 d-flex justify-lg-space-between">
      <v-card elevation="4" :width="mini ? minWidth : maxWidth">
        <v-navigation-drawer class="elevation-none"
                             v-model="drawer"
                             :mini-variant-width="minWidth"
                             :width="mini ? minWidth : maxWidth"
                             fixed
                             :mini-variant.sync="mini" permanent>
          <v-list three-line>
            <v-list-item :to="{name: 'dashboard.user.show'}">
              <v-list-item-avatar class="my-auto elevation-3">
                <template v-if="user['personal_data']">
                  <v-img :src="user['personal_data'].avatar ? user['personal_data'].avatar : require(`@/assets/avatar-${user['personal_data'].sex}.jpeg`)"
                         max-width="40.36"></v-img>
                </template>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  <small class="font-weight-bold">Welcome, {{ user.first_name }}</small>
                </v-list-item-title>
                <v-list-item-subtitle class="black--text">
                  <small>{{ user.email }}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <template v-for="item in items">
              <template v-if="item['submenu'].length === 0">
                <v-list-item
                    :key="item.text"
                    link
                    :to="{name: item.route}"
                    :active-class="item.route === $route.name ? 'v-list-item--active' : 'transparent'"
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-group
                    :key="item.text"
                    link
                    :to="{name: item.route}"
                    :active-class="item.route === $route.name ? 'v-list-item--active' : 'transparent'"
                >
                  <template v-slot:prependIcon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </template>
                  <template v-slot:appendIcon>
                    <v-icon color="primary">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                      v-for="sub in item['submenu']"
                      :key="sub.text"
                      :disabled="sub.disabled"
                      link
                      :to="{name: sub.route}"
                      class="ml-3"
                      :active-class="sub.route === $route.name ? 'v-list-item--active' : 'transparent'"
                  >
                    <v-list-item-icon>
                      <v-icon :color="sub.disabled ? 'grey' : 'primary'">{{ sub.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title :class="sub.disabled ? 'grey--text' : 'primary--text'">{{ sub.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
            <v-list-item class="px-2" style="border: none">
              <v-btn
                  color="primary"
                  icon
                  @click.stop="mini = !mini"
              >
                <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
              </v-btn>
              <small class="text-uppercase primary--text" v-if="!mini">Collapse</small>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card flat
              tile
              :width="mini ? $vuetify.breakpoint.width - minWidth - 5 : $vuetify.breakpoint.width - maxWidth - 5"
              :style="`padding-left: ${mini ? minWidth - 30 : maxWidth - minWidth * 3.5}px`"
              :min-height="$vuetify.breakpoint.height / 4">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <router-view/>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <preview-attachment-component/>
    <notification-component/>
    <change-password-dialog/>
  </v-row>
</template>

<script>
import sidebar from "@/static/sidebar.json"
import Pusher from "pusher-js"
import store from "../../store";

export default {
  name: "Dashboard",
  data () {
    return {
      selected: null,
      mini: false,
      drawer: true,
      channel: null,
      pusher: new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        forceTLS: false,
        authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${store.getters.GET_TOKEN}`,
            Accept: "application/json"
          }
        }
      })

    }
  },
  mounted() {
    this.subscribe()
  },
  beforeDestroy() {
    this.pusher.unbind()
  },
  methods: {
    subscribe () {
      this.pusher.subscribe(`private-report`)
          .bind('needed', () => {
            this.$http.get(`${process.env.VUE_APP_API_URL}/notify`)
            .then((response) => {
              // eslint-disable-next-line no-prototype-builtins
              if (response.data.hasOwnProperty('first_name')) {
                this.$store.dispatch('setNotification', {
                  show: true,
                  text: `${response.data.first_name}, you still don't report about your day.`
                })
              } else {
                this.$root.$emit('update-report', response.data.reported)
              }
            })
          })
    }
  },
  computed: {
    user() {
      return this.$store.getters.GET_USER
    },
    minWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 55
        case "lg":
          return 50
        default:
          return 55
      }
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 240
        case "lg":
          return 240
        default:
          return 220
      }
    },
    items () {
      return sidebar['sidebar']
    }
  }
}
</script>

<style scoped>

</style>
