<template>
  <v-row>
    <v-col cols="12" lg="9" xl="7"  class="mt-6">
      <validation-observer v-slot="{ passes }">
        <v-form @submit.prevent="passes( submit )" :disabled="form.status === 'done'">
          <v-card flat>
            <v-card-title class="d-block">
              <back-button-component/>
              <div class="d-flex">
                <span class="font-weight-bold d-block">Edit and update your {{ type }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="markAsDone" text plain :ripple="false" color="primary" :disabled="form.status === 'done'">
                  <template v-if="form.status !== 'done'">
                    <span class="font-weight-thin">Mark {{ type }} as done</span>
                  </template>
                  <template v-else>
                    <span class="font-weight-thin">{{ type.toUpperCase() }} is done</span>
                  </template>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text>
              <v-card class="rounded-lg" :loading="loading">
                <v-card-text class="pa-8">
                  <validation-provider name="headline" rules="required" v-slot="{ errors }">
                    <v-text-field type="text"
                                  autocomplete="title"
                                  :error-messages="errors"
                                  v-model="form.title"
                                  :hint="`Enter your ${type}'s headline`"
                                  persistent-placeholder
                                  :placeholder="`Your ${type}'s headline`">
                      <template v-slot:label>
                        <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s headline</label>
                      </template>
                    </v-text-field>
                  </validation-provider>
                  <validation-provider name="description" rules="required" v-slot="{ errors }">
                    <v-textarea type="text"
                                autocomplete="description"
                                :error-messages="errors"
                                v-model="form.description"
                                :hint="`Enter your ${type}'s description`"
                                persistent-placeholder
                                :placeholder="`Your ${type}'s description`">
                      <template v-slot:label>
                        <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s description</label>
                      </template>
                    </v-textarea>
                  </validation-provider>
                  <v-switch inset v-model="form.pinned">
                    <template v-slot:label>
                      <label class="font-weight-bold">Your {{ type }} is {{ form.pinned ? 'pinned' : 'not pinned' }} on top</label>
                    </template>
                  </v-switch>
                  <v-row>
                    <v-col cols="12" class="ma-0 mt-3">
                      <label class="font-weight-bold">Add attachments to your {{ type }}</label>
                    </v-col>
                  </v-row>
                  <template v-if="form.attachments">
                    <v-row>
                      <template v-if="form.attachments.length <= 5">
                        <v-col cols="12" class="ma-0 pa-0">
                          <v-row class="ma-0 pa-0 d-flex">
                            <v-col v-for="(image, i) in form.attachments"
                                   style="max-width: 75px; position: relative"
                                   :key="i">
                              <v-btn
                                  :disabled="form.status === 'done'"
                                  @click="deleteAttachment(image, i)"
                                  x-small
                                  style="position: absolute; top: -1px; right: 2px; z-index: 2"
                                  dark
                                  fab
                                  max-width="20"
                                  max-height="20"
                                  class="px-0"
                                  color="error">
                                <v-icon x-small>mdi-close</v-icon>
                              </v-btn>
                              <template v-if="image && image.hasOwnProperty('image_data')">
                                <v-img style="cursor: pointer" @click="preview(image)" class="elevation-1" :src="image.image_data"></v-img>
                              </template>
                              <template v-else>
                                <v-img style="cursor: pointer" @click="preview(image)" class="elevation-1" :src="image"></v-img>
                              </template>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-btn :disabled="form.status === 'done' || form.attachments.length >= 5" @click="openFileInput" icon color="primary" x-large>
                                <v-icon color="primary">mb-plus</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" style="position: absolute; left: -100%; width: 1px">
                          <validation-provider name="Attachments" rules="max_files:5" v-slot="{ errors }">
                            <v-file-input ref="file" v-model="form.additional"
                                          @change="chooseFiles"
                                          accept="image/jpeg,png"
                                          multiple
                                          :error-messages="errors">
                            </v-file-input>
                          </validation-provider>
                        </v-col>
                      </template>
                      <template v-if="form.attachments.length > 5">
                        <v-col v-for="image in form.attachments"
                               :key="image.id">
                          <v-img style="cursor: pointer" @click="preview(image)" :src="image.image_data" max-width="75"></v-img>
                        </v-col>
                      </template>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0 d-flex">
                          <v-col v-for="(image, i) in form.attachments"
                                 style="max-width: 75px; position: relative"
                                 :key="i">
                            <v-btn
                                :disabled="form.status === 'done'"
                                @click="deleteAttachment(image, i)"
                                x-small
                                style="position: absolute; top: -1px; right: 2px; z-index: 2"
                                dark
                                fab
                                max-width="20"
                                max-height="20"
                                class="px-0"
                                color="error">
                              <v-icon x-small>mdi-close</v-icon>
                            </v-btn>
                            <template v-if="image && image.hasOwnProperty('image_data')">
                              <v-img style="cursor: pointer" @click="preview(image)" class="elevation-1" :src="image.image_data"></v-img>
                            </template>
                            <template v-else>
                              <v-img style="cursor: pointer" @click="preview(image)" class="elevation-1" :src="image"></v-img>
                            </template>
                          </v-col>
                          <v-col class="d-flex align-center">
                            <v-btn :disabled="form.status === 'done'" @click="openFileInput" icon color="primary" x-large>
                              <v-icon color="primary">mb-plus</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" style="position: absolute; left: -100%; width: 1px">
                        <validation-provider name="Attachments" rules="max_files:5" v-slot="{ errors }">
                          <v-file-input ref="file" v-model="form.additional"
                                        @change="chooseFiles"
                                        accept="image/jpeg,png"
                                        multiple
                                        :error-messages="errors">
                          </v-file-input>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </template>
                </v-card-text>
                <v-card-actions class="pb-8 px-8">
                  <v-spacer/>
                  <v-btn v-if="form.status !== 'done'" width="89" height="49" rounded
                         class="d-flex align-center font-weight-bold"
                         color="secondary" type="button">
                    <span class="text-capitalize">Cancel</span>
                  </v-btn>
                  <v-btn :loading="loading"
                         :disabled="form.status === 'done'"
                         width="98" height="49" rounded class="d-flex align-center font-weight-bold"
                         color="primary" type="submit">
                    <span class="text-capitalize">Update</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>

</template>
<script>

export default {
  name: "EditItemComponent",
  props: {
    endpoint: String,
    type: String,
  },
  data () {
    return {
      loading: false,
      src: null,
      form: {
        file: []
      }
    }
  },
  mounted() {
    this.getItem()
  },
  methods: {
    getItem () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data[this.endpoint]
          })
    },
    chooseFiles(files) {
      files.forEach(file => {
        const reader = new FileReader()
        reader.onloadend = () => {
          // eslint-disable-next-line no-prototype-builtins
          if (!this.form.hasOwnProperty('attachments')) {
            this.form.attachments = []
            this.form.attachments.push(reader.result)
          } else {
            this.form.attachments.push(reader.result)
          }

        }
        if (file) {
          reader.readAsDataURL(file)
        }

      })
    },
    deleteAttachment(image, index) {
      if (typeof image === 'object') {
        this.$http.delete(`${process.env.VUE_APP_API_URL}/attachments/${image.id}`, {
          data: {
            parent_id: this.$route.params.id,
            parent_type: this.type
          }
        })
            .then(() => {
              this.form.attachments.splice(index, 1)
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.form.attachments = error.response.data
              }
            })
      } else {
        this.form.attachments.splice(index, 1)
      }

    },
    submit () {
      this.loading = true

      this.$http.patch(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${this.$route.params.id}`, this.form)
          .then(() => {
            this.loading = false
          })
    },
    openFileInput () {
      this.$refs.file.$refs.input.click();
    },
    markAsDone () {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/status/${this.endpoint}/${this.$route.params.id}`)
          .then(() => {
            this.$router.push({ name: `dashboard.${this.type}s.show` })
          })
    },
    preview (image) {
      this.$store.dispatch('setAttachmentPreview', {show: true, image: image['image_data']})
    }
  }
}
</script>

<style scoped>

</style>