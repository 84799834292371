<!--suppress ALL -->
<template>
  <v-card flat tile>
    <v-card-text class="pa-0">
      <v-row class="pa-0 ma-0">
        <v-col :cols="12" class="pa-0 ma-0">
          <v-card flat class="pa-0" min-height="400">
            <v-card-text class="px-0">
              <v-sheet rounded max-height="160" min-height="80" elevation="2" class="d-flex">
                <v-sheet height="80" min-height="80" width="80"
                         style="background-color: #F6F6F6"
                         class="d-flex justify-center align-center">
                  <span class="grey--text text-caption font-weight-bold">Total</span>
                </v-sheet>
                <v-sheet height="80" min-height="80" class="d-flex justify-center align-center flex-fill">
                  <v-row class="pa-0">
                    <v-col cols="12" class="mx-auto">
                      <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="d-flex justify-center align-center">
                          <span class="text-h5 font-weight-bold black--text">My {{ period }} progress</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-sheet>
                <v-sheet height="80" min-height="80" width="80"
                         style="background-color: #F6F6F6"
                         class="d-flex justify-center align-center">
                  <span class="grey--text text-caption font-weight-bold">Done</span>
                </v-sheet>
              </v-sheet>
              <v-divider/>
              <info-component
                  color="success"
                  :type="true"
                  background="success"
                  :value="progressPercentage(overview.ideas.done, overview.ideas.open)"
                  title="Ideas"
                  :quantity="{done: overview.ideas.done, open: overview.ideas.open}"
                  :icons="{ header: 'mb-paper-clip', done: 'mdi-check-circle', open: 'mdi-clock-time-three' }"/>
              <v-divider/>
              <info-component
                  color="error"
                  :type="true"
                  background="error"
                  :value="progressPercentage(overview.problems.done, overview.problems.open)"
                  title="Daily Problems"
                  :quantity="{done: overview.problems.done, open: overview.problems.open}"
                  :icons="{ header: 'mb-exclamation-filled', done: 'mdi-check-circle', open: 'mdi-clock-time-three' }"/>
              <v-divider/>
              <info-component
                  color="accent"
                  :type="true"
                  :value="progressPercentage(overview.priorities.done, overview.priorities.open)"
                  title="Top 5"
                  :quantity="{done: overview.priorities.done, open: overview.priorities.open}"
                  :icons="{ header: 'mb-star-outline', done: 'mdi-check-circle', open: 'mdi-clock-time-three' }"/>
              <v-divider/>
              <info-component
                  color="primary"
                  :type="false"
                  :value="progressPercentage(overview.unwows, overview.priorities.done + overview.priorities.open)"
                  title="Daily Wins"
                  :amount="overview.unwows"
                  :icons="{ header: 'mb-crossbox-outline', done: 'mdi-check-circle', open: 'mdi-clock-time-three' }"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "OverviewComponent",
  props: {
    componentData: Object
  },
  methods: {
    progressPercentage (done, open) {
      return (done / (done + open)) * 100
    }
  },
  computed: {
    overview () {
      return this.componentData ? this.componentData.overview : {}
    },
    period () {
      switch (this.$store.getters.GET_PERIOD) {
        case 1:
          return 'daily'
        case 7:
          return 'weekly'
        case 30:
          return 'monthly'
        case 365:
          return 'yearly'
      }
      return null
    }
  }
}
</script>

<style scoped lang="scss">


</style>