import Vue from "vue"
import AppBarComponent from "../components/AppBarComponent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GradientBackgroundComponent from "../components/GradientBackgroundComponent";
import { StripeElementCard } from "@vue-stripe/vue-stripe"
import MaskedInputFieldComponent from "../components/MaskedInputFieldComponent";
import ConfirmationActionComponent from "../components/ConfirmationActionComponent";
import AlertComponent from "../components/AlertComponent";
import TodayTab from "../components/tab-contents/TodayTab";
import WeekTab from "../components/tab-contents/WeekTab";
import MonthTab from "../components/tab-contents/MonthTab";
import YearTab from "../components/tab-contents/YearTab";
import OverviewComponent from "../components/OverviewComponent";
import BackButtonComponent from "../components/BackButtonComponent";
import SBIScoreComponent from "../components/SBIScoreComponent";
import ItemsListComponent from "../components/ItemsListComponent";
import CreateItemComponent from "../components/CreateItemComponent";
import InfoComponent from "../components/InfoComponent";
import EditItemComponent from "../components/EditItemComponent";
import ShowItemComponent from "../components/ShowItemComponent";
import PreviewAttachmentComponent from "../components/PreviewAttachmentComponent";
import ReportDataTableComponent from "../components/ReportDataTableComponent";
import NotificationComponent from "../components/NotificationComponent";
import ChangePasswordDialog from "../components/dialogs/ChangePasswordDialog";

Vue.component('app-bar-component', AppBarComponent)
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('gradient-background-component', GradientBackgroundComponent)
Vue.component('stripe-element-card', StripeElementCard)
Vue.component('masked-input-field-component', MaskedInputFieldComponent)
Vue.component('confirmation-action-component', ConfirmationActionComponent)
Vue.component('alert-component', AlertComponent)
Vue.component('today-tab', TodayTab)
Vue.component('week-tab', WeekTab)
Vue.component('month-tab', MonthTab)
Vue.component('year-tab', YearTab)
Vue.component('overview-component', OverviewComponent)
Vue.component('back-button-component', BackButtonComponent)
Vue.component('sbi-score-component', SBIScoreComponent)
Vue.component('items-data-table-component', ItemsListComponent)
Vue.component('create-item-component', CreateItemComponent)
Vue.component('edit-item-component', EditItemComponent)
Vue.component('show-item-component', ShowItemComponent)
Vue.component('info-component', InfoComponent)
Vue.component('preview-attachment-component', PreviewAttachmentComponent)
Vue.component('report-data-table-component', ReportDataTableComponent)
Vue.component('notification-component', NotificationComponent)
Vue.component('change-password-dialog', ChangePasswordDialog)