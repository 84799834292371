<template>
<v-dialog max-width="600" persistent v-model="show">
  <v-card>
    <v-overlay absolute color="grey" v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-card-title>Change password</v-card-title>
    <v-card-subtitle>You should change your optional password to your own</v-card-subtitle>
    <v-card-text class="px-0">
      <validation-observer ref="editProfile" v-slot="{ passes }">
        <v-form @submit.prevent="passes(submit)">
          <v-card-text>
            <v-row class="pa-0">
              <v-col class="py-0" cols="12">
                <validation-provider name="password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                  <v-text-field type="password"
                                autocomplete="password"
                                :error-messages="errors"
                                v-model="reset.password"
                                label="Password"
                                persistent-placeholder
                                placeholder="Please, edit your password"></v-text-field>
                </validation-provider>
                <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                  <v-text-field type="password"
                                autocomplete="password_confirmation"
                                :error-messages="errors"
                                v-model="reset.password_confirmation"
                                label="Password confirmation"
                                persistent-placeholder
                                placeholder="Please, confirm your password"></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-spacer></v-spacer>
            <v-btn :loading="loadingChange" height="49" width="35%" rounded type="submit" color="primary">change Password</v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "ChangePasswordDialog",
  data () {
    return {
      loading: false,
      loadingChange: false,
      reset: {
        email: null,
        token: null,
        password: null,
        password_validation: null
      }
    }
  },
  beforeMount() {
    if (this.$store.getters.GET_FIRST_LOGIN) {
      this.$http.post(`${process.env.VUE_APP_API_URL}/password-forgot`, this.user)
          .then((response) => {
            setTimeout(() => {
              this.loading = false
              this.reset.token = response.data.token
              this.reset.email = this.user.email
            }, 1500)
          })
    }
  },
  methods: {
    submit () {
      this.loadingChange = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/password-reset`, this.reset)
          .then(() => {
            this.loadingChange = false
            this.$store.dispatch('setToken', null)
            .then(() => {
              this.$store.dispatch('setRefreshToken', null)
              .then(() => {
                this.$store.dispatch('setUser', {})
                    .then(() => {
                      this.$router.push({name: 'login'}).then(() => {
                        this.$store.dispatch('setFirstLogin', false)
                      })
                    })
              })
            })

          })
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_FIRST_LOGIN
    },
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '80%'
        case 'sm': return '75%'
        case 'md': return '70%'
        case 'lg': return '85%'
        case 'xl': return '60%'
      }
      return '60%'
    },
    user() {
      return this.$store.getters.GET_USER
    }
  }
}
</script>

<style scoped>

</style>