<template>
  <overview-component :component-data="componentData" :period="period"/>
</template>

<script>
export default {
  name: "MonthTab",
  props: {
    componentData: Object,
    period: Number
  }
}
</script>

<style scoped>

</style>