import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify.plugin'
import headers from "./static/headers.json"
import periods from "./static/periods.json"
import i18n from './i18n'

import './scss/main.scss'
import './bootstrap/plugins.bootstrap'
import './bootstrap/components.bootstrap'
import './bootstrap/filters.bootstrap'
import './bootstrap/services.bootstrap'
import './vee-validate'

Vue.config.productionTip = false
Vue.prototype.$headers = headers
Vue.prototype.$periods = periods

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
