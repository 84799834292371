<template>
  <v-card tile flat class="rounded-lg pa-0" >
    <v-card-title class="font-weight-bold">{{ title }} SBI Score</v-card-title>
    <v-card class="rounded-lg">
      <v-card-subtitle class="font-weight-bold">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-tabs id="periodTabs" background-color="#F6F6F6"
                    :show-arrows="$vuetify.breakpoint.lgAndDown"
                    class="rounded-xl"
                    v-model="period" grow centered hide-slider
                    :style="`height: ${$vuetify.breakpoint.height * 0.05}px`">
              <template v-for="period in periods">
                <v-tab class="rounded-xl" :key="period.text"
                       @change="getSBIByPeriod(period.value)"
                       :active-class="period.text === period.text ? 'elevation-2 white' : ''"
                       :ripple="false">{{ period.text }}</v-tab>
              </template>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text style="height: 80%" class="d-flex justify-center align-center">
              <span class="font-weight-black primary--text display-4">
                {{ sbi ? sbi : 1 }}
              </span>
      </v-card-text>
      <v-card-actions class="px-8" style="height: 20%; background-color: rgba(0, 0, 0, .018) ">
        <v-icon color="lightgray">mdi-information-outline</v-icon>
        <v-btn :ripple="false" plain text color="lightgray">
          <span class="text-lowercase">more about</span>&nbsp;SBI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "SBIScoreComponent",
  props: {
    url: String,
    title: String
  },
  data () {
    return {
      period: null,
      sbi: null
    }
  },
  methods: {
    getSBIByPeriod(period) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/sbi/${this.url}/${period}`)
          .then(response => {
            this.sbi = response.data
          })
    }
  },
  computed: {
    periods () {
      return this.$periods.values
    }
  }
}
</script>

<style scoped>

</style>