<template>
  <v-row>
    <v-col cols="12" lg="9" xl="7" class="mt-6">
      <v-card flat class="pa-4">
        <v-card-title class="d-block px-0">
          <back-button-component/>
        </v-card-title>
        <v-card>
          <v-card-text>
            <v-card flat class="rounded-lg" :loading="loading">
              <v-card-title>
                <span class="font-weight-bold d-block">{{ form.title }}</span>
              </v-card-title>
              <v-card-text class="pa-5">
                {{ form.description }}
              </v-card-text>
              <v-card-text class="pa-5">
                <template v-if="form.attachments">
                  <v-row class="d-flex">
                    <v-col v-for="image in form.attachments"
                           cols="2"
                           :key="image.id">
                      <v-img style="cursor: pointer" contain @click="preview(image)" class="elevation-1" :src="image['image_data']"></v-img>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
  </v-row>

</template>
<script>

export default {
  name: "ShowItemComponent",
  props: {
    endpoint: String,
    title: String
  },
  data () {
    return {
      loading: false,
      form: {}
    }
  },
  mounted() {
    this.getItem()
  },
  methods: {
    getItem () {
      this.$http.get(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data[this.endpoint]
            console.log(this.form)
          })
    },
    preview (image) {
      this.$store.dispatch('setAttachmentPreview', {show: true, image: image['image_data']})
    }
  }
}
</script>

<style scoped>

</style>