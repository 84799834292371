/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewarePipeline from "./middlewarePipeline";
import auth from "./middlewares/auth";
import loggedIn from "./middlewares/loggedIn";
import store from '../store'
import Dashboard from "../views/dashboard/Dashboard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/privacy-and-terms',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/request-membership',
    name: 'request-membership',
    component: () => import('../views/Registration.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: '',
        component: () => import('../views/dashboard/subviews/Activity.vue'),
        name: 'dashboard',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/me/edit',
        component: () => import('../views/dashboard/subviews/user/Edit.vue'),
        name: 'dashboard.me.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/activities',
        component: () => import('../views/dashboard/subviews/Activity.vue'),
        name: 'dashboard.activities',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/ideas',
        component: () => import('../views/dashboard/subviews/ideas/Ideas.vue'),
        name: 'dashboard.ideas',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/ideas/create',
        component: () => import('../views/dashboard/subviews/ideas/Create.vue'),
        name: 'dashboard.ideas.create',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/ideas/:id/edit',
        component: () => import('../views/dashboard/subviews/ideas/Edit.vue'),
        name: 'dashboard.ideas.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/ideas/:id/show',
        component: () => import('../views/dashboard/subviews/ideas/Show.vue'),
        name: 'dashboard.ideas.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/problems',
        component: () => import('../views/dashboard/subviews/problems/Problems.vue'),
        name: 'dashboard.problems',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/problems/create',
        component: () => import('../views/dashboard/subviews/problems/Create.vue'),
        name: 'dashboard.problems.create',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/problems/:id/edit',
        component: () => import('../views/dashboard/subviews/problems/Edit.vue'),
        name: 'dashboard.problems.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/problems/:id/show',
        component: () => import('../views/dashboard/subviews/problems/Show.vue'),
        name: 'dashboard.problems.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/top-5-priorities',
        component: () => import('../views/dashboard/subviews/top/Top.vue'),
        name: 'dashboard.wows',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/top-5-priorities/create',
        component: () => import('../views/dashboard/subviews/top/Create.vue'),
        name: 'dashboard.wows.create',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/top-5-priorities/:id/edit',
        component: () => import('../views/dashboard/subviews/top/Edit.vue'),
        name: 'dashboard.wows.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/top-5-priorities/:id/show',
        component: () => import('../views/dashboard/subviews/top/Show.vue'),
        name: 'dashboard.wows.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/daily-wins',
        component: () => import('../views/dashboard/subviews/unwows/Unwow.vue'),
        name: 'dashboard.dailywins',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/daily-wins/create',
        component: () => import('../views/dashboard/subviews/unwows/Create.vue'),
        name: 'dashboard.dailywins.create',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/daily-wins/:id/edit',
        component: () => import('../views/dashboard/subviews/unwows/Edit.vue'),
        name: 'dashboard.dailywins.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/daily-wins/:id/show',
        component: () => import('../views/dashboard/subviews/unwows/Show.vue'),
        name: 'dashboard.dailywins.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/me',
        component: () => import('../views/dashboard/subviews/user/Show.vue'),
        name: 'dashboard.user.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/daily-report',
        component: () => import('../views/dashboard/subviews/reports/Report.vue'),
        name: 'dashboard.report',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/aha-report/:id/show',
        component: () => import('../views/dashboard/subviews/reports/Show.vue'),
        name: 'dashboard.report.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/aha-report/:id/edit',
        component: () => import('../views/dashboard/subviews/reports/Edit.vue'),
        name: 'dashboard.report.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/calendar',
        component: () => import('../views/dashboard/subviews/Calendar.vue'),
        name: 'dashboard.calendar',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/settings',
        component: () => import('../views/dashboard/subviews/settings/Settings.vue'),
        name: 'dashboard.settings',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/settings/notifications',
        component: () => import('../views/dashboard/subviews/settings/Notifications.vue'),
        name: 'dashboard.settings.notifications',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/settings/payment-methods',
        component: () => import('../views/dashboard/subviews/settings/Payments.vue'),
        name: 'dashboard.settings.payments',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/settings/date-format',
        component: () => import('../views/dashboard/subviews/settings/DateFormat.vue'),
        name: 'dashboard.settings.dateformat',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/privacy-and-terms',
        component: () => import('../views/dashboard/subviews/settings/Privacy.vue'),
        name: 'dashboard.privacy-and-terms',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/privacy',
        component: () => import('../views/dashboard/subviews/settings/Privacy.vue'),
        name: 'dashboard.privacy',
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setOverlay', true).catch()

  if (to.name) {
    if (to.name.includes('dashboard')) {
      store.dispatch('setIsDashboard', true).catch()
    }
    else {
      store.dispatch('setIsDashboard', false).catch()
    }
  }

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch('setOverlay', false).catch()
  }, 800)

})

export default router

