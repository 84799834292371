<template>
  <v-sheet rounded max-height="160" min-height="80" elevation="2" class="d-flex">
    <v-sheet height="80" min-height="80" width="80"
             style="background-color: #F6F6F6"
             class="d-flex justify-center align-center">
      <span v-if="type" :class="`${color}--text display-1 font-weight-bold`">{{ quantity.open + quantity.done }}</span>
    </v-sheet>
    <v-sheet height="80" min-height="80" class="d-flex justify-center align-center flex-fill">
      <v-row class="pa-0">
        <v-col cols="11" class="mx-auto">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="d-flex justify-start align-center mb-n3">
              <v-icon :color="color">{{ icons.header }}</v-icon>
              <span :class="`ml-2 text-h5 font-weight-thin ${color}--text`">{{ title }}</span>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-space-between mt-n2">
              <v-progress-linear rounded
                                 height="10"
                                 :value="value"
                                 :color="color"
                                 :background-color="background"
                                 background-opacity="0.24"></v-progress-linear>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet height="80" min-height="80" width="80"
             style="background-color: #F6F6F6"
             class="d-flex justify-center align-center">
      <span v-if="type" :class="`${color}--text display-1 font-weight-bold`">{{ quantity.done }}</span>
      <span v-else :class="`${color}--text display-1 font-weight-bold`">{{ amount }}</span>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "InfoComponent",
  props: {
    value: Number,
    color: String,
    background: String,
    title: String,
    icons: Object,
    quantity: Object,
    type: Boolean,
    amount: Number
  }
}
</script>

<style scoped>

</style>