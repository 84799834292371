/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import store from '../../store'
import router from "../../router";
const $http = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'Content-Type': 'application/json'
  },
  withCredentials: false,
  crossDomain: true,
  params: {}
})

$http.interceptors.request.use(request => {
  request.headers['Accept-Language'] = document.documentElement.lang
  if (request.url.includes('login')) {
    request.data.grant_type = process.env.VUE_APP_GRANT_TYPE
    request.data.client_id = Number(process.env.VUE_APP_GRANT_CLIENT_ID)
    request.data.client_secret = process.env.VUE_APP_GRANT_CLIENT_SECRET
  } else {
    request.headers.Authorization = `Bearer ${store.getters.GET_TOKEN}`
  }
  return request
})

$http.interceptors.response.use(response => {

  if (response.request.responseURL.includes('login')) {
    store.dispatch('setResponse', { status: true, type: 'success', message: 'You were successfully authorized.' }).catch()
  }
  if (response.data.hasOwnProperty('success')) {
    store.dispatch('setResponse', { status: true, type: 'success', message: response.data.success }).catch()
  }
  if (response.data.hasOwnProperty('refresh_token')) {
    store.dispatch('setRefreshToken', response.data.refresh_token).then(() => {})
  }
  if (response.data.hasOwnProperty('access_token')) {
    store.dispatch('setToken', response.data.access_token).then(() => {
      $http.get(`${process.env.VUE_APP_API_URL}/auth/me`)
          .then( response => {
            store.dispatch('setUser', response.data['me'])
                .then(() => {
                  router.push({name: 'dashboard'}).then(() => {
                    if (store.getters.GET_USER.first) {
                      store.dispatch('setFirstLogin', true).catch()
                    }
                  })
                })
          })
    })
  }
  return response
}, error => {
  switch (error.response.status) {
    case 400:
      store.dispatch('setResponse', { status: true, type: 'error', message: `Sorry, something went wrong. Error status: ${error.response.status} ${error.response.data.hasOwnProperty('error_description') ? "Error description: \"" + error.response.data['error_description'].replace('.', '') + "\"" : ''}. If you received this message several times &mdash; please, contact support"` }).catch()
      break
    case 401:
      store.dispatch('setResponse', { status: true, type: 'error', message: 'Sorry, you are not authorized. Authorize yourself first' }).then()
      return
    case 403:
      store.dispatch('setResponse', { status: true, type: 'error', message: error.response.data.error }).catch()
      break
    case 404:
      store.dispatch('setResponse', { status: true, type: 'error', message: error.response.data.error }).then()
      return
    case 409:
      store.dispatch('setResponse', { status: true, type: 'error', message: error.response.data.error }).catch()
      break
    case 500:
      store.dispatch('setResponse', { status: true, type: 'error', message: error.response.data.error }).catch()
      break
    default:
      break
  }
})

const Http = {
  install (Vue) {
    Vue.prototype.$http = $http
  }
}

export default (Http)
